import * as axios from 'axios';
import {
  TOURNAMENT_STATUS_CREATED,
  TOURNAMENT_STATUS_REGISTRATION_OPEN,
  TOURNAMENT_STATUS_REGISTRATION_CLOSED,
  TOURNAMENT_STATUS_READY,
  TOURNAMENT_STATUS_IN_PROGRESS,
  TOURNAMENT_STATUS_COMPLETED,
  API_URL_BASE,
  ALL_ROLLS,
  ALL_PRIMARY_STATS,
  TOAST_OPTIONS,
} from './ClientConstants';

import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Button } from 'reactstrap';
import cogoToast from 'cogo-toast';

import kazMetFile from './KazarossXG2.met';
import * as gameplay from './Gameplay';

/**
 *
 * PUBLIC FUNCTIONS
 *
 */
export function getCheckersAfterMove(xgid, move) {
  const checkers = extractCheckersFromXgid(xgid);
  const sequences = move.split(' ');

  sequences.forEach((sequence) => {
    gameplay.executeSequence({
      checkers,
      sequence,
      reverse: false,
    });
  });

  // Return reversed checkers
  return checkers.reverse().map((e) => -e);
}

export function extractCheckersFromXgid(xgid) {
  const idxOfEq = xgid.indexOf('=');
  if (idxOfEq === -1) {
    return null;
  }
  const fields = xgid.substring(idxOfEq + 1).split(':');

  //Field 1: checkers
  const checkers = fields[0]
    .split('')
    .map((c) =>
      c === '-'
        ? 0
        : c.charCodeAt(0) >= 'A'.charCodeAt(0) &&
          c.charCodeAt(0) <= 'Z'.charCodeAt(0)
        ? c.charCodeAt(0) - 'A'.charCodeAt(0) + 1
        : 'a'.charCodeAt(0) - c.charCodeAt(0) - 1
    );

  //Field 4: turn
  const turn = parseInt(fields[3]);
  if (turn < 0) {
    return checkers.reverse().map((e) => -e);
  } else {
    return checkers;
  }
}

export function convertCheckersToObject(checkers) {
  const obj = {};
  for (let j = 0; j <= 25; j++) {
    obj['c' + j] = checkers[j];
  }
  return obj;
}

export function pickFields(obj, fields) {
  const newObj = {};
  fields.forEach((field) => (newObj[field] = obj[field]));
  return newObj;
}

export function convertToCSV(data) {
  // Get the headers
  const headers = Object.keys(data[0]).join(',') + '\n';

  // Convert rows
  const rows = data
    .map((row) =>
      Object.values(row)
        .map((value) => `"${value}"`) // Handle values with commas
        .join(',')
    )
    .join('\n');

  return headers + rows;
}

export function downloadCSV(data) {
  const csv = convertToCSV(data);
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = 'data.csv';
  link.click();

  // Clean up the URL object
  URL.revokeObjectURL(url);
}

export function getPairIdentifier(pair) {
  if (pair.length === 2) {
    return pair[0]._id + '+' + pair[1]._id;
  } else {
    return pair[0]._id;
  }
}

export function pairsEqual(pair1, pair2) {
  if (!pair1 || !pair2) {
    return false;
  }
  if (pair1.length !== pair2.length) {
    return false;
  }
  if (pair1.length === 1) {
    return pair1[0] === pair2[0];
  } else if (pair1.length === 2) {
    return (
      (pair1[0] === pair2[0] && pair1[1] === pair2[1]) ||
      (pair1[0] === pair2[1] && pair1[1] === pair2[0])
    );
  }

  //Length greater than 2 is not used, but leaving this code below just in case

  for (let j = 0; j < pair1.length; j++) {
    if (pair1[j] !== pair2[j]) {
      return false;
    }
  }
  return true;
}

export function nameTrim(name) {
  const lastSpaceIndex = name.lastIndexOf(' ');
  return name[0] + '.' + name.substring(lastSpaceIndex);
}

export function openLinkInNewTab(link) {
  window.open(link, '_blank');
}

export function generateConsecutiveArray(max, min = 1) {
  var indexes = [];
  for (var j = min; j <= max; j++) {
    indexes.push(j);
  }
  return indexes;
}

export function expandGroupSymbols(symbols, sizes, headerOnly) {
  if (symbols.length !== sizes.length) return null;

  let arr = [];
  symbols.forEach((symbol, index) => {
    for (let j = 0; j < sizes[index]; j++) {
      arr.push({
        label:
          headerOnly && j !== 0
            ? ''
            : symbol + (headerOnly ? '' : '  (p' + (j + 1) + ')'),
        groupIndex: index,
      });
    }
  });
  return arr;
}

export function getKoPhaseCount(count) {
  return Math.ceil(Math.log2(count));
}

export function getKoRoundedCount(count) {
  return Math.pow(2, getKoPhaseCount(count));
}

export function getKoFirstPhase(count) {
  return Math.pow(2, getKoPhaseCount(count) - 1);
}

export function getKoPhases(koFirstPhase) {
  var phases = [];
  var phase = koFirstPhase;
  while (phase >= 1) {
    phases.push(phase);
    phase /= 2;
  }
  return phases;
}

export function getKoIndexes(koPhase) {
  return generateConsecutiveArray(koPhase);
}

export function getDefaultKoPairings(groupToKoAdvance) {
  //TODO something smarter than this
  return getGroupToKoIdentifiers(groupToKoAdvance);
}

export function getGroupToKoIdentifiers(groupToKoAdvance) {
  let ids = [];
  const groupSymbols = generateGroupSymbols(groupToKoAdvance.length);
  groupToKoAdvance.forEach((adv, index) => {
    for (let j = 1; j <= adv; j++) {
      ids.push(groupSymbols[index] + '-' + j);
    }
  });

  const count = groupToKoAdvance.slice(0).reduce((a, b) => a + b, 0);
  const byes = getKoRoundedCount(count) - count;
  for (let j = 0; j < byes; j++) {
    ids.push('BYE');
  }
  return ids;
}

export function generateUiKoSymbol(koPhase, koIndex) {
  if (koPhase === 1) {
    return 'FINAL';
  } else {
    return '1/' + koPhase + ' #' + koIndex;
  }
}

export function getAllTournamentTypes() {
  return ['League', 'KO', 'Cup', 'Swiss'];
}

export function getAllScoringSystems() {
  return [
    'Victory 1p',
    'Victory 1p + (1 - PR/10) [p]',
    'Victory 1p + (1 - PR/15) [p]',
    'Victory 1p + (1 - PR/20) [p]',
    'Victory 1p + Best PR 1p',
  ];
}

export function getSystemIdFromTournamentType(str) {
  return getAllTournamentTypes().findIndex((type) => type === str);
}

export function setApiKey(apiKey) {
  axios.defaults.headers.common = {
    'X-API-KEY': apiKey,
  };
}

export function quickPost(url, params, alertFunc, errorPrefix, processJson) {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: !token
        ? undefined
        : `Bearer ${localStorage.getItem('token')}`,
    },
  };
  axios
    .post(API_URL_BASE + url, params, config)
    .then((res) => {
      return {
        ...res.data,
        responseSize: parseInt(res.headers['x-body-size']),
        responseServerDurationMs: parseFloat(res.headers['x-response-time']),
      };
    })
    .then((j) => {
      console.log(
        `[${url}] Size ${j.responseSize} Duration ${j.responseServerDurationMs} ms`
      );
      if (j.error) {
        alertFunc('SERVER ERROR', errorPrefix + JSON.stringify(j.error));
      } else {
        processJson(j);
      }
    })
    .catch((err) => {
      alertFunc('ERROR', errorPrefix + err);
    });
}

export function quickGet(url, params, alertFunc, errorPrefix, processJson) {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: !token
        ? undefined
        : `Bearer ${localStorage.getItem('token')}`,
    },
  };
  axios
    .get(API_URL_BASE + url, { params, ...config })
    .then((res) => {
      return {
        ...res.data,
        responseSize: parseInt(res.headers['x-body-size']),
        responseServerDurationMs: parseFloat(res.headers['x-response-time']),
      };
    })
    .then((j) => {
      console.log(
        `[${url}] Size ${j.responseSize} Duration ${j.responseServerDurationMs} ms`
      );
      if (j.error) {
        alertFunc('SERVER ERROR', errorPrefix + JSON.stringify(j.error));
      } else {
        processJson(j);
      }
    })
    .catch((err) => {
      alertFunc('ERROR', errorPrefix + err);
    });
}

function arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
}

export function fileGet(url, params, alertFunc, errorPrefix, processImageUrl) {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: !token
        ? undefined
        : `Bearer ${localStorage.getItem('token')}`,
    },
  };
  axios
    .get(API_URL_BASE + url, { params, ...config })
    .then((res) => {
      //console.log(res);

      var base64Flag = 'data:image/jpg;base64,';
      var imageStr = arrayBufferToBase64(res.data);
      const img = base64Flag + imageStr;

      /*
      const blobUrl = URL.createObjectURL(new Blob([img], ));
      window.open(blobUrl, 'about:blank');
      */

      let w = window.open('about:blank');
      let image = new Image();
      image.src = img;
      setTimeout(function () {
        w.document.write(image.outerHTML);
      }, 100);

      /*
      var image = new Image();
      image.src = img;

      var w = window.open('');
      w.document.write(image.outerHTML);
      */

      //const url = window.URL.createObjectURL(new Blob([res.data]));
      //processImageUrl(url);
      //let w = window.open('about:blank');
    })
    .catch((err) => {
      alertFunc('ERROR', errorPrefix + err);
    });
}

export function fileDownload(url, alertFunc, errorPrefix, downloadedFileName) {
  const token = localStorage.getItem('token');
  axios
    .get(API_URL_BASE + url, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Authorization: !token
          ? undefined
          : `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      let nameEnding = res.config.url.substring(res.config.url.length - 5);
      let dotIndex = nameEnding.indexOf('.');
      let extension = null;
      if (dotIndex !== -1) {
        extension = nameEnding.substring(dotIndex);
      }
      link.download =
        (downloadedFileName || 'downloadedFile') + (extension || '');
      link.click();
    })
    .catch((err) => {
      alertFunc && alertFunc('ERROR', errorPrefix + err);
    });
}

export function avatarDownload(url, alertFunc, errorPrefix) {
  axios
    .get(API_URL_BASE + url, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      //console.log(res);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      const filename = 'avatar.png';
      link.download = filename;
      link.click();
    })
    .catch((err) => {
      alertFunc && alertFunc('ERROR', errorPrefix + err);
    });
}

export function fileUpload(
  url,
  params,
  file,
  alertFunc,
  errorPrefix,
  processSuccess
) {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: !token
        ? undefined
        : `Bearer ${localStorage.getItem('token')}`,
    },
  };
  const formData = new FormData();
  formData.append('file', file, file.name);
  for (const [key, value] of Object.entries(params)) {
    formData.append(key, value);
  }
  axios
    .post(API_URL_BASE + url, formData, config)
    .then((res) => res.data)
    .then((j) => {
      if (j.error) {
        alertFunc('SERVER ERROR', errorPrefix + JSON.stringify(j.error));
      } else {
        processSuccess();
      }
    })
    .catch((err) => {
      alertFunc('ERROR', errorPrefix + err);
    });
}

export function quickGetMultiple(
  urlArr,
  paramsArr,
  alertFunc,
  errorPrefix,
  processJsons
) {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: !token
        ? undefined
        : `Bearer ${localStorage.getItem('token')}`,
    },
  };
  const requests = urlArr.map((url, index) =>
    axios.get(API_URL_BASE + url, { params: paramsArr[index], ...config })
  );
  axios
    .all(requests)
    .then(
      axios.spread((...responses) => {
        const jsons = responses.map((r) => r.data);
        let errorFound = false;
        jsons.forEach((j) => {
          if (j.error) {
            alertFunc('SERVER ERROR', errorPrefix + JSON.stringify(j.error));
            errorFound = true;
          } else if (j.warning) {
            alertFunc('SERVER WARNING', j.warning);
          }
        });
        if (!errorFound) {
          processJsons(...jsons);
        }
      })
    )
    .catch((errors) => {
      alertFunc('ERROR', errorPrefix + errors);
    });
}

export function quickPostMultiple(
  urlArr,
  paramsArr,
  alertFunc,
  errorPrefix,
  processJsons
) {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: !token
        ? undefined
        : `Bearer ${localStorage.getItem('token')}`,
    },
  };
  const requests = urlArr.map((url, index) =>
    axios.post(API_URL_BASE + url, paramsArr[index], config)
  );
  axios
    .all(requests)
    .then(
      axios.spread((...responses) => {
        const jsons = responses.map((r) => r.data);
        let errorFound = false;
        jsons.forEach((j) => {
          if (j.error) {
            alertFunc('SERVER ERROR', errorPrefix + JSON.stringify(j.error));
            errorFound = true;
          } else if (j.warning) {
            alertFunc('SERVER WARNING', j.warning);
          }
        });
        if (!errorFound) {
          processJsons(...jsons);
        }
      })
    )
    .catch((errors) => {
      alertFunc('ERROR', errorPrefix + errors);
    });
}

export function generateGroupSymbols(count) {
  if (count > 26) {
    count = 26;
  }
  let symbols = [];
  let symbol = 'A';
  for (let j = 0; j < count; j++) {
    symbols.push(symbol);
    symbol = String.fromCharCode(symbol.charCodeAt(0) + 1);
  }
  return symbols;
}

export function repeatInArray(count, value) {
  let arr = [];
  for (let j = 0; j < count; j++) {
    arr.push(value);
  }
  return arr;
}

export function getTournamentStateName(state) {
  switch (state) {
    case TOURNAMENT_STATUS_CREATED:
      return 'Tournament Created';
    case TOURNAMENT_STATUS_REGISTRATION_OPEN:
      return 'Registration Open';
    case TOURNAMENT_STATUS_REGISTRATION_CLOSED:
      return 'Registration Closed';
    case TOURNAMENT_STATUS_READY:
      return 'Tournament Ready';
    case TOURNAMENT_STATUS_IN_PROGRESS:
      return 'Tournament In Progress';
    case TOURNAMENT_STATUS_COMPLETED:
      return 'Tournament Completed';
    default:
      return 'Invalid state: ' + state;
  }
}

export function getShuffledGroupParticipantNames(
  groupSymbols,
  groupSizes,
  registeredParticipants,
  gpiStr
) {
  /*
  console.log(
    ' SGC inputs:',
    groupSymbols,
    groupSizes,
    registeredParticipants,
    gpiStr
  );*/
  var gpi;
  if (gpiStr != null) {
    try {
      gpi = JSON.parse(gpiStr);
    } catch (err) {
      gpi = {};
    }
  } else {
    gpi = {};
  }
  groupSymbols.forEach((symbol) => {
    if (gpi[symbol] == null) {
      gpi[symbol] = [];
    }
  });
  const allNames = registeredParticipants.map((p) => p.name);
  let arr = [];
  groupSymbols.forEach((symbol, index) => {
    for (let j = 0; j < groupSizes[index]; j++) {
      if (gpi[symbol].length > j && gpi[symbol][j] != null) {
        const name = registeredParticipants.find(
          (p) => p._id === gpi[symbol][j]
        ).name;
        arr.push(name);
        allNames.splice(
          allNames.findIndex((n) => n === name),
          1
        );
      } else {
        arr.push('<empty>');
      }
    }
  });

  allNames.forEach((name) => arr.push(name));
  //console.log(' SGC: ', arr);
  return arr;
}

export function getGpiCount(groupSymbols, gpiStr) {
  var gpi;
  if (gpiStr != null) {
    try {
      gpi = JSON.parse(gpiStr);
    } catch (err) {
      gpi = {};
    }
  } else {
    gpi = {};
  }
  groupSymbols.forEach((symbol) => {
    if (gpi[symbol] == null) {
      gpi[symbol] = [];
    }
  });

  let count = 0;
  groupSymbols.forEach((symbol) => {
    count += gpi[symbol].length;
  });
  return count;
}

export function generateGpiStrFromShuffledList(
  groupSymbols,
  groupSizes,
  registeredParticipants,
  shuffledList
) {
  const gpi = {};
  var accumulatedIndex = 0;
  groupSymbols.forEach((symbol, index) => {
    gpi[symbol] = [];
    for (let j = 0; j < groupSizes[index]; j++) {
      const name = shuffledList[accumulatedIndex];
      accumulatedIndex++;

      const participant = registeredParticipants.find((p) => p.name === name);
      if (participant != null) {
        gpi[symbol].push(participant._id);
      }
    }
  });

  //console.log(' Output GPI', gpi);
  return JSON.stringify(gpi);
}

export function initGpi(groupSymbols) {
  const gpi = {};
  groupSymbols.forEach((symbol) => {
    gpi[symbol] = [];
  });
  return gpi;
}

export function unarchivePtd(ptd) {
  const participants = ptd.system.hasTeams ? ptd.teams : ptd.players;

  if (ptd.system.hasGroups) {
    ptd.system.groupSymbols.forEach((symbol) => {
      const newGpArr = ptd.groupParticipants[symbol].map(
        (p) => participants[p]
      );
      ptd.groupParticipants[symbol] = newGpArr;

      ptd.groupRounds[symbol].forEach((round) => {
        const newGmArr = ptd.groupMatchups[symbol][round].map((mu) =>
          mu.map((p) => participants[p])
        );
        ptd.groupMatchups[symbol][round] = newGmArr;

        const newGmsArr = ptd.groupMatches[symbol][round].map(
          (m) => ptd.matches[m]
        );
        ptd.groupMatches[symbol][round] = newGmsArr;
      });
    });
  }

  if (ptd.system.hasKos) {
    getKoPhases(ptd.system.koFirstPhase).forEach((phase) => {
      getKoIndexes(phase).forEach((index) => {
        const newKmArr = ptd.koMatchups[phase][index].map(
          (p) => participants[p]
        );
        ptd.koMatchups[phase][index] = newKmArr;

        generateConsecutiveArray(ptd.system.koLegs).forEach((leg) => {
          const newKmsArr = ptd.koMatches[phase][index][leg].map(
            (m) => ptd.matches[m]
          );
          ptd.koMatches[phase][index][leg] = newKmsArr;
        });
      });
    });
  }

  if (ptd.winnersTop) {
    ptd.winnersTop.forEach((place) => {
      ptd.winners[place] = participants[ptd.winners[place]];
    });
  }

  if (ptd.prMedals) {
    [1, 2, 3].forEach((place) => {
      ptd.prMedals[place] = ptd.players[ptd.prMedals[place]];
    });
  }
}

export function initializeTeamUiElements(team) {
  team.uiCountryFlagAndName = (
    <div>
      <ReactCountryFlag countryCode='RO' />
      {' ' + team.name}
    </div>
  );
}

export function initializePlayerUiElements(player) {
  player.uiCountryFlagAndName = (
    <div>
      <ReactCountryFlag countryCode={player.countryCode} />
      {' ' + player.name}
    </div>
  );
}

export function initializeMatchUiElements(
  match,
  hasTeams,
  players,
  teams,
  onSim
) {
  const leftPlayer = players.find(
    (player) => player._id === match.leftPlayerId
  );
  const rightPlayer = players.find(
    (player) => player._id === match.rightPlayerId
  );
  if (!leftPlayer || !rightPlayer) {
    console.log(
      ' ERROR processing match in initializeMatchUiElements, player IDs not found in the group for match: ',
      match
    );
    return;
  }
  const leftTeam = hasTeams
    ? teams.find((team) => team._id === match.leftTeamId)
    : null;
  const rightTeam = hasTeams
    ? teams.find((team) => team._id === match.rightTeamId)
    : null;
  if (hasTeams && (!leftTeam || !rightTeam)) {
    console.log(
      ' ERROR processing match in initializeMatchUiElements, team IDs not found in the group for match: ',
      match
    );
    return;
  }

  if (hasTeams) {
    match.uiPlayersWithFlags = (
      <div>
        <div>{leftPlayer.name + ' [' + leftTeam.name + ']'}</div>
        <div>{' - '}</div>
        <div>{rightPlayer.name + ' [' + rightTeam.name + ']'}</div>
      </div>
    );
  } else {
    match.uiPlayersWithFlags = (
      <div>
        {leftPlayer.uiCountryFlagAndName} {' - '}
        {rightPlayer.uiCountryFlagAndName}
      </div>
    );
  }

  if (match.leftScore) {
    match.uiScore = (
      <div>
        <div>{match.leftScore} </div>
        <div>{' - '}</div>
        <div>{match.rightScore}</div>
      </div>
    );
  } else {
    match.uiScore = (
      <div>
        <Button
          onClick={() => {
            if (onSim) onSim(match._id);
          }}
        >
          Sim
        </Button>
      </div>
    );
  }
  match.uiPrs = (
    <div>
      <div>{Math.round(match.leftPr * 100) / 100}</div>
      <div>{' - '} </div>
      <div>{Math.round(match.rightPr * 100) / 100}</div>
    </div>
  );
}

export function fetchProPic(proCode, onUrlObtained) {
  fetch('/api/data/pro_pic?proCode=' + proCode)
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      onUrlObtained(url);
    });
}

export function convertIndObj2ArrFunc(count) {
  return (object) => {
    const arr = [];
    for (let index = 1; index <= count; index++) {
      arr.push(object[index]);
    }
    return arr;
  };
}

export function convertIndObj2Arr(object) {
  const arr = [];
  for (let index = 1; object[index] !== undefined; index++) {
    arr.push(object[index]);
  }
  return arr;
}

export function convertArr2IndObj(array) {
  const obj = {};
  for (let index = 1; index <= array.length; index++) {
    obj[index] = array[index - 1];
  }
  return obj;
}

export function converSuperObject(superObject, properties, convertFunc) {
  const newSuperObj = {};
  properties.forEach((property) => {
    newSuperObj[property] = convertFunc(superObject[property]);
  });
  return newSuperObj;
}

export function convertPairingsFromGroupsArr2Obj(pfgArr) {
  const obj = {};
  generateConsecutiveArray(pfgArr.length / 2).forEach((index) => {
    obj[index] = pfgArr.slice(2 * (index - 1), 2 * index);
  });
  return obj;
}
export function convertPairingsFromGroupsObj2Arr(pfgObj, maxIndex) {
  const arr = [];
  generateConsecutiveArray(maxIndex).forEach((index) => {
    arr.push(...pfgObj[index]);
  });
  return arr;
}

export function objectMap(obj, properties, mapFunc) {
  const newObj = {};
  properties.forEach((property) => {
    newObj[property] = mapFunc(obj[property]);
  });
  return newObj;
}

export function groupingPlusUnassigned(grouping, unassigned, unassignedLabel) {
  const newGrouping = { ...grouping };
  newGrouping[unassignedLabel] = unassigned;
  return newGrouping;
}

export function groupsPlusUnassigned(groups, unassignedLabel) {
  const newGroups = groups.slice();
  newGroups.push(unassignedLabel);
  return newGroups;
}

export function objMinusProp(obj, prop) {
  const newObj = { ...obj };
  delete newObj[prop];
  return newObj;
}

export function twoDigit(value) {
  return value < 10 ? '0' + value : '' + value;
}

export function capitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1);
}

export async function asyncForEach(array, callback, log) {
  const step = Math.round(array.length / 10);
  if (log)
    console.log(
      ' Processing ' +
        array.length +
        ' elements... [logging every ' +
        step +
        ']'
    );
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);

    if ((index + 1) % step === 0) {
      if (log)
        console.log(
          ' <' +
            (index + 1) / step +
            '/' +
            Math.round(array.length / step) +
            '>'
        );
    }
  }
}

export async function asyncForN(count, callback, log) {
  const step = Math.round(count / 10);
  if (log)
    console.log(
      ' Processing ' + count + ' elements... [logging every ' + step + ']'
    );
  for (let index = 0; index < count; index++) {
    await callback(index + 1);

    if ((index + 1) % step === 0) {
      if (log)
        console.log(
          ' <' + (index + 1) / step + '/' + Math.round(count / step) + '>'
        );
    }
  }
}

export async function asyncForEachParallel(array, callback) {
  var promises = [];
  for (let index = 0; index < array.length; index++) {
    promises.push(callback(array[index], index, array));
  }
  await Promise.all(promises);
}

export async function asyncForNParallel(count, callback) {
  var promises = [];
  for (let index = 0; index < count; index++) {
    promises.push(callback(index + 1));
  }
  await Promise.all(promises);
}

export function randInt(min, max) {
  return min + Math.floor(Math.random() * (max - min + 1));
}

export function categoryTopSymbol(category, top) {
  return category + '.' + top;
}

export function tokenizeRkoRound(phase, index) {
  if (phase === 1) {
    return 'F';
  }
  if (phase === 2) {
    return 'SF#' + index;
  }
  if (phase === 4) {
    return 'QF#' + index;
  }
  return '1/' + phase + '#' + index;
}

export function objSortFunc(property, reverse) {
  return (obj1, obj2) => {
    if (obj1[property] < obj2[property]) {
      return reverse ? +1 : -1;
    } else if (obj1[property] > obj2[property]) {
      return reverse ? -1 : +1;
    } else {
      return 0;
    }
  };
}

export function timeDelta(time1, time2) {
  const ms = Math.abs(time1 - time2);
  let sec = Math.round(ms / 1000);
  let min = Math.floor(sec / 60);
  let hr = Math.floor(min / 60);
  const days = Math.floor(hr / 24);
  hr = hr % 24;
  min = min % 60;
  const totalSec = sec;
  sec = sec % 60;

  const completeString =
    (days ? days + 'd ' : '') +
    (hr ? hr + 'hr ' : '') +
    (min ? min + 'min ' : '') +
    sec +
    'sec';

  return { days, hr, min, sec, totalSec, completeString };
}

function extractLineStats(line) {
  const lineItems = line.split('\t');
  if (lineItems.length !== 13) {
    return null;
  }
  if (line[0] !== '\t') {
    if (lineItems[2] !== '' || lineItems[3] !== '') {
      return null;
    }
    return {
      isFirst: true,
      rolls: lineItems[0].split(','),
      move: lineItems[1],
      equity: parseFloat(lineItems[4]),
      loseBg: parseFloat(lineItems[5]) / 100.0,
      loseGmn: parseFloat(lineItems[6]) / 100.0,
      loseSg: parseFloat(lineItems[7]) / 100.0,
      winSg: parseFloat(lineItems[8]) / 100.0,
      winGmn: parseFloat(lineItems[9]) / 100.0,
      winBg: parseFloat(lineItems[10]) / 100.0,
    };
  } else {
    if (lineItems[0] !== '' || lineItems[1] !== '') {
      return null;
    }
    return {
      isFirst: false,
      rolls: lineItems[2].split(','),
      move: lineItems[3],
      equity: parseFloat(lineItems[4]),
      loseBg: parseFloat(lineItems[5]) / 100.0,
      loseGmn: parseFloat(lineItems[6]) / 100.0,
      loseSg: parseFloat(lineItems[7]) / 100.0,
      winSg: parseFloat(lineItems[8]) / 100.0,
      winGmn: parseFloat(lineItems[9]) / 100.0,
      winBg: parseFloat(lineItems[10]) / 100.0,
    };
  }
}

export function parseDiceDistribution(ddStr) {
  /* Step 1: extract first roll stats with second roll breakdown from the text input */
  const dd = {
    firstRollStats: {
      average: {
        equity: 0,
        loseBg: 0,
        loseGmn: 0,
        loseSg: 0,
        winSg: 0,
        winGmn: 0,
        winBg: 0,
      },
    },
  };
  const lines = ddStr.split('\n');
  let currentFirstRolls = null;
  let currentFirstRollProgress;
  let error = lines.length < 3;
  lines.slice(1, lines.length - 1).forEach((line) => {
    const lineStats = extractLineStats(line);
    if (!lineStats) {
      error = true;
      return;
    }
    if (!currentFirstRolls) {
      if (!lineStats.isFirst) {
        error = true;
        return;
      }
      currentFirstRolls = lineStats.rolls;
      currentFirstRollProgress = 0;
      currentFirstRolls.forEach((firstRoll) => {
        dd.firstRollStats[firstRoll] = {
          move: lineStats.move,
          weight: firstRoll[0] === firstRoll[1] ? 1 : 2,
          equity: lineStats.equity,
          loseBg: lineStats.loseBg,
          loseGmn: lineStats.loseGmn,
          loseSg: lineStats.loseSg,
          winSg: lineStats.winSg,
          winGmn: lineStats.winGmn,
          winBg: lineStats.winBg,
          winGr: lineStats.winGmn / lineStats.winSg,
          loseGr: lineStats.loseGmn / lineStats.loseSg,
          secondRollStats: {},
        };
        ALL_PRIMARY_STATS.forEach((stat) => {
          dd.firstRollStats.average[stat] +=
            dd.firstRollStats[firstRoll].weight *
            dd.firstRollStats[firstRoll][stat];
        });
      });
    } else {
      if (lineStats.isFirst) {
        error = true;
        return;
      }
      currentFirstRolls.forEach((firstRoll, index) => {
        lineStats.rolls.forEach((secondRoll) => {
          dd.firstRollStats[firstRoll].secondRollStats[secondRoll] = {
            move: lineStats.move,
            weight: secondRoll[0] === secondRoll[1] ? 1 : 2,
            equity: lineStats.equity,
            loseBg: lineStats.loseBg,
            loseGmn: lineStats.loseGmn,
            loseSg: lineStats.loseSg,
            winSg: lineStats.winSg,
            winGmn: lineStats.winGmn,
            winBg: lineStats.winBg,
            winGr: lineStats.winGmn / lineStats.winSg,
            loseGr: lineStats.loseGmn / lineStats.loseSg,
          };
          if (index === 0) {
            currentFirstRollProgress +=
              dd.firstRollStats[firstRoll].secondRollStats[secondRoll].weight;
          }
        });
      });
      if (currentFirstRollProgress === 36) {
        currentFirstRolls = null;
      }
    }
  });
  if (error) {
    return null;
  }

  /* Step 2: compute first roll averages */
  ALL_PRIMARY_STATS.forEach((stat) => {
    dd.firstRollStats.average[stat] /= 36.0;
  });
  dd.firstRollStats.average.winGr =
    dd.firstRollStats.average.winGmn / dd.firstRollStats.average.winSg;
  dd.firstRollStats.average.loseGr =
    dd.firstRollStats.average.loseGmn / dd.firstRollStats.average.loseSg;

  /* Step 3: compute reverse stats */
  dd.secondRollStats = {
    average: {},
  };
  ALL_PRIMARY_STATS.forEach((stat) => {
    dd.secondRollStats.average[stat] = 0;
  });
  ALL_ROLLS.forEach((secondRoll) => {
    dd.secondRollStats[secondRoll] = {
      move: '-',
      weight: secondRoll[0] === secondRoll[1] ? 1 : 2,
      firstRollStats: {},
    };
    ALL_PRIMARY_STATS.forEach((stat) => {
      dd.secondRollStats[secondRoll][stat] = 0;
    });
    ALL_ROLLS.forEach((firstRoll) => {
      dd.secondRollStats[secondRoll].firstRollStats[firstRoll] = {
        ...dd.firstRollStats[firstRoll].secondRollStats[secondRoll],
        weight: dd.firstRollStats[firstRoll].weight,
        move:
          dd.firstRollStats[firstRoll].move +
          ' & [' +
          secondRoll +
          '] ' +
          dd.firstRollStats[firstRoll].secondRollStats[secondRoll].move,
      };
      ALL_PRIMARY_STATS.forEach((stat) => {
        dd.secondRollStats[secondRoll][stat] +=
          dd.secondRollStats[secondRoll].firstRollStats[firstRoll].weight *
          dd.secondRollStats[secondRoll].firstRollStats[firstRoll][stat];
      });
    });
    ALL_PRIMARY_STATS.forEach((stat) => {
      dd.secondRollStats[secondRoll][stat] /= 36;
      dd.secondRollStats.average[stat] +=
        dd.secondRollStats[secondRoll].weight *
        dd.secondRollStats[secondRoll][stat];
    });
    dd.secondRollStats[secondRoll].winGr =
      dd.secondRollStats[secondRoll].winGmn /
      dd.secondRollStats[secondRoll].winSg;
    dd.secondRollStats[secondRoll].loseGr =
      dd.secondRollStats[secondRoll].loseGmn /
      dd.secondRollStats[secondRoll].loseSg;
  });
  ALL_PRIMARY_STATS.forEach((stat) => {
    dd.secondRollStats.average[stat] /= 36;
  });
  dd.secondRollStats.average.winGr =
    dd.secondRollStats.average.winGmn / dd.firstRollStats.average.winSg;
  dd.secondRollStats.average.loseGr =
    dd.secondRollStats.average.loseGmn / dd.firstRollStats.average.loseSg;

  return dd;
}

export function computeDeltaDd(leftDd, rightDd) {
  const deltaDd = {
    firstRollStats: { average: {} },
    secondRollStats: { average: {} },
  };
  /* First roll stats */
  ALL_ROLLS.forEach((firstRoll) => {
    deltaDd.firstRollStats[firstRoll] = {
      move:
        leftDd.firstRollStats[firstRoll].move !==
        rightDd.firstRollStats[firstRoll].move
          ? leftDd.firstRollStats[firstRoll].move +
            ' <vs> ' +
            rightDd.firstRollStats[firstRoll].move
          : leftDd.firstRollStats[firstRoll].move + ' <ew>',
      weight: firstRoll[0] === firstRoll[1] ? 1 : 2,
      secondRollStats: {},
    };
    ALL_PRIMARY_STATS.concat(['winGr', 'loseGr']).forEach((stat) => {
      deltaDd.firstRollStats[firstRoll][stat] =
        leftDd.firstRollStats[firstRoll][stat] -
        rightDd.firstRollStats[firstRoll][stat];
    });
    ALL_ROLLS.forEach((secondRoll) => {
      deltaDd.firstRollStats[firstRoll].secondRollStats[secondRoll] = {
        move:
          leftDd.firstRollStats[firstRoll].secondRollStats[secondRoll].move !==
          rightDd.firstRollStats[firstRoll].secondRollStats[secondRoll].move
            ? leftDd.firstRollStats[firstRoll].secondRollStats[secondRoll]
                .move +
              ' <vs> ' +
              rightDd.firstRollStats[firstRoll].secondRollStats[secondRoll].move
            : leftDd.firstRollStats[firstRoll].secondRollStats[secondRoll]
                .move + ' <ew>',
        weight: secondRoll[0] === secondRoll[1] ? 1 : 2,
      };
      ALL_PRIMARY_STATS.concat(['winGr', 'loseGr']).forEach((stat) => {
        deltaDd.firstRollStats[firstRoll].secondRollStats[secondRoll][stat] =
          leftDd.firstRollStats[firstRoll].secondRollStats[secondRoll][stat] -
          rightDd.firstRollStats[firstRoll].secondRollStats[secondRoll][stat];
      });
    });
  });
  ALL_PRIMARY_STATS.concat(['winGr', 'loseGr']).forEach((stat) => {
    deltaDd.firstRollStats.average[stat] =
      leftDd.firstRollStats.average[stat] -
      rightDd.firstRollStats.average[stat];
  });
  /* Second roll stats */
  ALL_ROLLS.forEach((secondRoll) => {
    deltaDd.secondRollStats[secondRoll] = {
      move: '-',
      weight: secondRoll[0] === secondRoll[1] ? 1 : 2,
      firstRollStats: {},
    };
    ALL_PRIMARY_STATS.concat(['winGr', 'loseGr']).forEach((stat) => {
      deltaDd.secondRollStats[secondRoll][stat] =
        leftDd.secondRollStats[secondRoll][stat] -
        rightDd.secondRollStats[secondRoll][stat];
    });
    ALL_ROLLS.forEach((firstRoll) => {
      deltaDd.secondRollStats[secondRoll].firstRollStats[firstRoll] = {
        move:
          leftDd.secondRollStats[secondRoll].firstRollStats[firstRoll].move !==
          rightDd.secondRollStats[secondRoll].firstRollStats[firstRoll].move
            ? leftDd.secondRollStats[secondRoll].firstRollStats[firstRoll]
                .move +
              ' <vs> ' +
              rightDd.secondRollStats[secondRoll].firstRollStats[firstRoll].move
            : leftDd.secondRollStats[secondRoll].firstRollStats[firstRoll]
                .move + ' <ew>',
        weight: firstRoll[0] === firstRoll[1] ? 1 : 2,
      };
      ALL_PRIMARY_STATS.concat(['winGr', 'loseGr']).forEach((stat) => {
        deltaDd.secondRollStats[secondRoll].firstRollStats[firstRoll][stat] =
          leftDd.secondRollStats[secondRoll].firstRollStats[firstRoll][stat] -
          rightDd.secondRollStats[secondRoll].firstRollStats[firstRoll][stat];
      });
    });
  });
  ALL_PRIMARY_STATS.concat(['winGr', 'loseGr']).forEach((stat) => {
    deltaDd.secondRollStats.average[stat] =
      leftDd.secondRollStats.average[stat] -
      rightDd.secondRollStats.average[stat];
  });
  return deltaDd;
}

export function computeSortedDeltaDd(leftDd, rightDd) {
  //console.log('LeftDd: ', leftDd);
  //console.log('RightDd: ', rightDd);
  const _leftSortedDd = ALL_ROLLS.map(
    (roll) => leftDd.firstRollStats[roll].equity / (roll[0] === roll[1] ? 2 : 1)
  ).sort((a, b) => a - b);
  //console.log(leftSortedDd);
  const _rightSortedDd = ALL_ROLLS.map(
    (roll) =>
      rightDd.firstRollStats[roll].equity / (roll[0] === roll[1] ? 2 : 1)
  ).sort((a, b) => a - b);
  //console.log(rightSortedDd);

  let leftSortedDd = [];
  let rightSortedDd = [];
  ALL_ROLLS.forEach((roll) => {
    const times = roll[0] == roll[1] ? 1 : 2;
    for (let j = 0; j < times; j++) {
      leftSortedDd.push(leftDd.firstRollStats[roll].equity);
      rightSortedDd.push(rightDd.firstRollStats[roll].equity);
    }
  });
  leftSortedDd.sort((a, b) => a - b);
  rightSortedDd.sort((a, b) => a - b);

  const sortedDeltaDd = leftSortedDd.map(
    (leftEq, index) => leftEq - rightSortedDd[index]
  );
  //console.log(sortedDeltaDd);
  return sortedDeltaDd;
}

export async function loadMet() {
  const met = {};
  const metFile = await (await fetch(kazMetFile)).text();
  const lines = metFile.split('\n').filter(Boolean);

  /* Store Post-Crawford values in met[0] */
  const pcArr = lines[8]
    .substring(5)
    .split(' ')
    .map((n) => parseFloat(n));
  met[0] = {};
  pcArr.forEach((value, index) => {
    met[0][index + 1] = value;
  });

  /* Store all others in met[a][b] */
  generateConsecutiveArray(25).forEach((away) => {
    const arr = lines[11 + away]
      .substring(3)
      .split(' ')
      .map((n) => parseFloat(n));
    met[away] = {};
    arr.forEach((value, index) => {
      met[away][index + 1] = value;
    });
  });

  //console.log(JSON.stringify(met));

  return met;
}

export function showMembersOnlyToast() {
  cogoToast.error(
    'This is a members-only feature. Please log into your PRo account for access.',
    TOAST_OPTIONS
  );
}

export function toastAlertFunc(title, message) {
  const toastOptions = { ...TOAST_OPTIONS };
  toastOptions.heading = title;
  cogoToast.error(message, toastOptions);
}

export function toastErrorFunc(title, message) {
  const toastOptions = { ...TOAST_OPTIONS };
  toastOptions.heading = title;
  cogoToast.error(message, toastOptions);
}

export function toastSuccessFunc(title, message) {
  const toastOptions = { ...TOAST_OPTIONS };
  toastOptions.heading = title;
  cogoToast.success(message, toastOptions);
}

export function isActiveMember(user) {
  const exp = new Date(user.membershipExpirationDate).getTime();
  const now = Date.now();
  return exp > now;
}

export function isLifetimeMember(user) {
  const expYear = new Date(user.membershipExpirationDate).getFullYear();
  const birthYear = new Date(user.birthDate).getFullYear();
  return expYear === birthYear + 100;
}

export function sendEmail(toEmails, subject, message, pretty, callback) {
  const params = {
    toEmails,
    subject,
    message,
  };
  if (pretty) {
    params.pretty = true;
  }
  quickPost(
    '/api/data/table/sendEmail',
    params,
    toastErrorFunc,
    'Error sending email',
    (j) => {
      if (callback) {
        callback();
      }
    }
  );
}

export function romanianSortableTranform(str) {
  let newStr = str;
  newStr = newStr.replaceAll('ș', 'szz');
  newStr = newStr.replaceAll('ț', 'tzz');
  newStr = newStr.replaceAll('Ș', 'Szz');
  newStr = newStr.replaceAll('Ț', 'Tzz');
  newStr = newStr.replaceAll('ă', 'azy');
  newStr = newStr.replaceAll('â', 'azz');
  newStr = newStr.replaceAll('î', 'izz');
  newStr = newStr.replaceAll('Ă', 'Azy');
  newStr = newStr.replaceAll('Â', 'Azz');
  newStr = newStr.replaceAll('Î', 'Îzz');
  return newStr;
}

export function decompressStandings({ participants, settings, standings }) {
  if (!participants || !settings || !standings || !standings.compressed) {
    return;
  }

  if (settings.general.hasSwiss) {
    standings.tables = {
      OVERALL: standings.compressed.tables.OVERALL.map((participant) => ({
        ...participant,
        ...participants[participant.pIndex],
      })),
    };

    standings.matchups = {};
    standings.allSwissRounds.forEach((symbol) => {
      standings.matchups[symbol] = standings.compressed.matchups[symbol].map(
        (matchup) => {
          const leftParticipant = participants[matchup.leftIndex];
          const rightParticipant = participants[matchup.rightIndex];

          return {
            leftId: leftParticipant && leftParticipant._id,
            rightId: rightParticipant && rightParticipant._id,
            leftProCode: leftParticipant && leftParticipant.proCode,
            rightProCode: rightParticipant && rightParticipant.proCode,
            leftCountryCode: leftParticipant && leftParticipant.countryCode,
            rightCountryCode: rightParticipant && rightParticipant.countryCode,
            leftName: leftParticipant && leftParticipant.name,
            rightName: rightParticipant && rightParticipant.name,
            matches: [
              {
                ...matchup.match,
                leftId: leftParticipant && leftParticipant._id,
                rightId: rightParticipant && rightParticipant._id,
                leftProCode: leftParticipant && leftParticipant.proCode,
                rightProCode: rightParticipant && rightParticipant.proCode,
                leftCountryCode: leftParticipant && leftParticipant.countryCode,
                rightCountryCode:
                  rightParticipant && rightParticipant.countryCode,
                leftName: leftParticipant && leftParticipant.name,
                rightName: rightParticipant && rightParticipant.name,
              },
            ],
          };
        }
      );
    });

    delete standings.compressed;
  }
}

export const KAZAROSS_XG2_MET = {
  0: {
    1: 0.5,
    2: 0.48803,
    3: 0.32264,
    4: 0.31002,
    5: 0.19012,
    6: 0.18072,
    7: 0.11559,
    8: 0.10906,
    9: 0.06953,
    10: 0.065161,
    11: 0.042069,
    12: 0.03906,
    13: 0.025371,
    14: 0.023428,
    15: 0.015304,
    16: 0.01405,
    17: 0.00924,
    18: 0.00842,
    19: 0.00556,
    20: 0.00505,
    21: 0.00336,
    22: 0.00303,
    23: 0.00203,
    24: 0.00182,
    25: 0.00123,
  },
  1: {
    1: 0.5,
    2: 0.67736,
    3: 0.75076,
    4: 0.81436,
    5: 0.84179,
    6: 0.88731,
    7: 0.90724,
    8: 0.9325,
    9: 0.94402,
    10: 0.959275,
    11: 0.966442,
    12: 0.975534,
    13: 0.979845,
    14: 0.985273,
    15: 0.987893,
    16: 0.99114,
    17: 0.99273,
    18: 0.99467,
    19: 0.99563,
    20: 0.99679,
    21: 0.99737,
    22: 0.99807,
    23: 0.99842,
    24: 0.99884,
    25: 0.99905,
  },
  2: {
    1: 0.32264,
    2: 0.5,
    3: 0.59947,
    4: 0.6687,
    5: 0.74359,
    6: 0.7994,
    7: 0.84225,
    8: 0.87539,
    9: 0.90197,
    10: 0.923034,
    11: 0.939311,
    12: 0.95247,
    13: 0.962495,
    14: 0.970701,
    15: 0.976887,
    16: 0.98196,
    17: 0.9858,
    18: 0.98893,
    19: 0.99129,
    20: 0.99322,
    21: 0.99466,
    22: 0.99585,
    23: 0.99675,
    24: 0.99746,
    25: 0.99802,
  },
  3: {
    1: 0.24924,
    2: 0.40053,
    3: 0.5,
    4: 0.5715,
    5: 0.64795,
    6: 0.71123,
    7: 0.76209,
    8: 0.80468,
    9: 0.84017,
    10: 0.870638,
    11: 0.894417,
    12: 0.914831,
    13: 0.930702,
    14: 0.944426,
    15: 0.954931,
    16: 0.96399,
    17: 0.97093,
    18: 0.97687,
    19: 0.98139,
    20: 0.98522,
    21: 0.98814,
    22: 0.99062,
    23: 0.99248,
    24: 0.99407,
    25: 0.99527,
  },
  4: {
    1: 0.18564,
    2: 0.3313,
    3: 0.4285,
    4: 0.5,
    5: 0.57732,
    6: 0.64285,
    7: 0.69924,
    8: 0.74577,
    9: 0.78799,
    10: 0.824059,
    11: 0.853955,
    12: 0.879141,
    13: 0.900233,
    14: 0.91804,
    15: 0.932657,
    16: 0.94495,
    17: 0.95499,
    18: 0.96341,
    19: 0.97021,
    20: 0.97589,
    21: 0.98044,
    22: 0.98422,
    23: 0.98726,
    24: 0.98975,
    25: 0.99174,
  },
  5: {
    1: 0.15821,
    2: 0.25641,
    3: 0.35205,
    4: 0.42268,
    5: 0.5,
    6: 0.56635,
    7: 0.62638,
    8: 0.67786,
    9: 0.7254,
    10: 0.767055,
    11: 0.802732,
    12: 0.833654,
    13: 0.859934,
    14: 0.882866,
    15: 0.902013,
    16: 0.91847,
    17: 0.93223,
    18: 0.94397,
    19: 0.95367,
    20: 0.96189,
    21: 0.96864,
    22: 0.97432,
    23: 0.97896,
    24: 0.98283,
    25: 0.986,
  },
  6: {
    1: 0.11269,
    2: 0.2006,
    3: 0.28877,
    4: 0.35715,
    5: 0.43365,
    6: 0.5,
    7: 0.56261,
    8: 0.61636,
    9: 0.66787,
    10: 0.713057,
    11: 0.753427,
    12: 0.788634,
    13: 0.819569,
    14: 0.846648,
    15: 0.869999,
    16: 0.89021,
    17: 0.90756,
    18: 0.92246,
    19: 0.93508,
    20: 0.94583,
    21: 0.95488,
    22: 0.96254,
    23: 0.96894,
    24: 0.97432,
    25: 0.97879,
  },
  7: {
    1: 0.09276,
    2: 0.15775,
    3: 0.23791,
    4: 0.30076,
    5: 0.37362,
    6: 0.43739,
    7: 0.5,
    8: 0.5548,
    9: 0.60854,
    10: 0.656283,
    11: 0.700209,
    12: 0.739054,
    13: 0.774121,
    14: 0.805203,
    15: 0.832566,
    16: 0.85659,
    17: 0.87761,
    18: 0.89591,
    19: 0.91171,
    20: 0.92535,
    21: 0.93702,
    22: 0.94703,
    23: 0.95553,
    24: 0.96276,
    25: 0.96887,
    26: null,
  },
  8: {
    1: 0.0675,
    2: 0.12461,
    3: 0.19532,
    4: 0.25423,
    5: 0.32214,
    6: 0.38364,
    7: 0.4452,
    8: 0.5,
    9: 0.55442,
    10: 0.603718,
    11: 0.649899,
    12: 0.691356,
    13: 0.729447,
    14: 0.763593,
    15: 0.794397,
    16: 0.82158,
    17: 0.84578,
    18: 0.86714,
    19: 0.88589,
    20: 0.9023,
    21: 0.91658,
    22: 0.92898,
    23: 0.93968,
    24: 0.94891,
    25: 0.95682,
  },
  9: {
    1: 0.05598,
    2: 0.09803,
    3: 0.15983,
    4: 0.21201,
    5: 0.2746,
    6: 0.33213,
    7: 0.39146,
    8: 0.44558,
    9: 0.5,
    10: 0.550196,
    11: 0.597926,
    12: 0.641481,
    13: 0.682119,
    14: 0.718927,
    15: 0.752814,
    16: 0.78301,
    17: 0.81037,
    18: 0.83483,
    19: 0.85662,
    20: 0.87591,
    21: 0.89294,
    22: 0.90791,
    23: 0.92098,
    24: 0.9324,
    25: 0.9423,
  },
  10: {
    1: 0.040725,
    2: 0.076966,
    3: 0.129362,
    4: 0.175941,
    5: 0.232945,
    6: 0.286943,
    7: 0.343717,
    8: 0.396282,
    9: 0.449804,
    10: 0.5,
    11: 0.548547,
    12: 0.593459,
    13: 0.63588,
    14: 0.67483,
    15: 0.711113,
    16: 0.74371,
    17: 0.77375,
    18: 0.80093,
    19: 0.82543,
    20: 0.84741,
    21: 0.86703,
    22: 0.88448,
    23: 0.89991,
    24: 0.91353,
    25: 0.9255,
  },
  11: {
    1: 0.033558,
    2: 0.060689,
    3: 0.105583,
    4: 0.146045,
    5: 0.197268,
    6: 0.246573,
    7: 0.299791,
    8: 0.350101,
    9: 0.402074,
    10: 0.451453,
    11: 0.5,
    12: 0.545552,
    13: 0.589242,
    14: 0.629736,
    15: 0.667927,
    16: 0.70303,
    17: 0.7353,
    18: 0.76494,
    19: 0.79198,
    20: 0.81648,
    21: 0.83862,
    22: 0.85849,
    23: 0.87629,
    24: 0.89214,
    25: 0.90622,
  },
  12: {
    1: 0.024466,
    2: 0.04753,
    3: 0.085169,
    4: 0.120859,
    5: 0.166346,
    6: 0.211366,
    7: 0.260946,
    8: 0.308644,
    9: 0.358519,
    10: 0.406541,
    11: 0.454448,
    12: 0.5,
    13: 0.544068,
    14: 0.585701,
    15: 0.625259,
    16: 0.66178,
    17: 0.6961,
    18: 0.72778,
    19: 0.75703,
    20: 0.78381,
    21: 0.80826,
    22: 0.83044,
    23: 0.85051,
    24: 0.86856,
    25: 0.88476,
  },
  13: {
    1: 0.020155,
    2: 0.037505,
    3: 0.069298,
    4: 0.099767,
    5: 0.140066,
    6: 0.180431,
    7: 0.225879,
    8: 0.270553,
    9: 0.317881,
    10: 0.36412,
    11: 0.410758,
    12: 0.455932,
    13: 0.5,
    14: 0.541943,
    15: 0.582545,
    16: 0.62036,
    17: 0.65619,
    18: 0.68966,
    19: 0.72081,
    20: 0.74963,
    21: 0.77619,
    22: 0.80054,
    23: 0.82276,
    24: 0.84295,
    25: 0.86123,
  },
  14: {
    1: 0.014727,
    2: 0.029299,
    3: 0.055574,
    4: 0.08196,
    5: 0.117134,
    6: 0.153352,
    7: 0.194797,
    8: 0.236407,
    9: 0.281073,
    10: 0.32517,
    11: 0.370264,
    12: 0.414299,
    13: 0.458057,
    14: 0.5,
    15: 0.54075,
    16: 0.57942,
    17: 0.61634,
    18: 0.65117,
    19: 0.68391,
    20: 0.71448,
    21: 0.7429,
    22: 0.76917,
    23: 0.79339,
    24: 0.81559,
    25: 0.83586,
  },
  15: {
    1: 0.012107,
    2: 0.023113,
    3: 0.045069,
    4: 0.067343,
    5: 0.097987,
    6: 0.130001,
    7: 0.167434,
    8: 0.205603,
    9: 0.247186,
    10: 0.288887,
    11: 0.332073,
    12: 0.374741,
    13: 0.417455,
    14: 0.45925,
    15: 0.5,
    16: 0.53916,
    17: 0.57679,
    18: 0.61261,
    19: 0.64659,
    20: 0.67859,
    21: 0.70862,
    22: 0.73664,
    23: 0.76265,
    24: 0.78669,
    25: 0.80883,
  },
  16: {
    1: 0.00886,
    2: 0.01804,
    3: 0.03601,
    4: 0.05505,
    5: 0.08153,
    6: 0.10979,
    7: 0.14341,
    8: 0.17842,
    9: 0.21699,
    10: 0.25629,
    11: 0.29697,
    12: 0.33822,
    13: 0.37964,
    14: 0.42058,
    15: 0.46084,
    16: 0.5,
    17: 0.53796,
    18: 0.57441,
    19: 0.60929,
    20: 0.64241,
    21: 0.67376,
    22: 0.70323,
    23: 0.73084,
    24: 0.75657,
    25: 0.78046,
  },
  17: {
    1: 0.00727,
    2: 0.0142,
    3: 0.02907,
    4: 0.04501,
    5: 0.06777,
    6: 0.09244,
    7: 0.12239,
    8: 0.15422,
    9: 0.18963,
    10: 0.22625,
    11: 0.2647,
    12: 0.3039,
    13: 0.34381,
    14: 0.38366,
    15: 0.42321,
    16: 0.46204,
    17: 0.5,
    18: 0.53676,
    19: 0.57222,
    20: 0.60618,
    21: 0.63856,
    22: 0.66925,
    23: 0.69822,
    24: 0.72542,
    25: 0.75087,
  },
  18: {
    1: 0.00533,
    2: 0.01107,
    3: 0.02313,
    4: 0.03659,
    5: 0.05603,
    6: 0.07754,
    7: 0.10409,
    8: 0.13286,
    9: 0.16517,
    10: 0.19907,
    11: 0.23506,
    12: 0.27222,
    13: 0.31034,
    14: 0.34883,
    15: 0.38739,
    16: 0.42559,
    17: 0.46324,
    18: 0.5,
    19: 0.53574,
    20: 0.57023,
    21: 0.60336,
    22: 0.63501,
    23: 0.6651,
    24: 0.69356,
    25: 0.72038,
  },
  19: {
    1: 0.00437,
    2: 0.00871,
    3: 0.01861,
    4: 0.02979,
    5: 0.04633,
    6: 0.06492,
    7: 0.08829,
    8: 0.11411,
    9: 0.14338,
    10: 0.17457,
    11: 0.20802,
    12: 0.24297,
    13: 0.27919,
    14: 0.31609,
    15: 0.35341,
    16: 0.39071,
    17: 0.42778,
    18: 0.46426,
    19: 0.5,
    20: 0.53475,
    21: 0.56838,
    22: 0.60073,
    23: 0.63171,
    24: 0.66122,
    25: 0.68921,
  },
  20: {
    1: 0.00321,
    2: 0.00678,
    3: 0.01478,
    4: 0.02411,
    5: 0.03811,
    6: 0.05417,
    7: 0.07465,
    8: 0.0977,
    9: 0.12409,
    10: 0.15259,
    11: 0.18352,
    12: 0.21619,
    13: 0.25037,
    14: 0.28552,
    15: 0.32141,
    16: 0.35759,
    17: 0.39382,
    18: 0.42977,
    19: 0.46525,
    20: 0.5,
    21: 0.53387,
    22: 0.56667,
    23: 0.5983,
    24: 0.62864,
    25: 0.6576,
  },
  21: {
    1: 0.00263,
    2: 0.00534,
    3: 0.01186,
    4: 0.01956,
    5: 0.03136,
    6: 0.04512,
    7: 0.06298,
    8: 0.08342,
    9: 0.10706,
    10: 0.13297,
    11: 0.16138,
    12: 0.19174,
    13: 0.22381,
    14: 0.2571,
    15: 0.29138,
    16: 0.32624,
    17: 0.36144,
    18: 0.39664,
    19: 0.43162,
    20: 0.46613,
    21: 0.5,
    22: 0.53303,
    23: 0.56508,
    24: 0.59603,
    25: 0.62576,
  },
  22: {
    1: 0.00193,
    2: 0.00415,
    3: 0.00938,
    4: 0.01578,
    5: 0.02568,
    6: 0.03746,
    7: 0.05297,
    8: 0.07102,
    9: 0.09209,
    10: 0.11552,
    11: 0.14151,
    12: 0.16956,
    13: 0.19946,
    14: 0.23083,
    15: 0.26336,
    16: 0.29677,
    17: 0.33075,
    18: 0.36499,
    19: 0.39927,
    20: 0.43333,
    21: 0.46697,
    22: 0.5,
    23: 0.53226,
    24: 0.5636,
    25: 0.59391,
  },
  23: {
    1: 0.00158,
    2: 0.00325,
    3: 0.00752,
    4: 0.01274,
    5: 0.02104,
    6: 0.03106,
    7: 0.04447,
    8: 0.06032,
    9: 0.07902,
    10: 0.10009,
    11: 0.12371,
    12: 0.14949,
    13: 0.17724,
    14: 0.20661,
    15: 0.23735,
    16: 0.26916,
    17: 0.30178,
    18: 0.3349,
    19: 0.36829,
    20: 0.4017,
    21: 0.43492,
    22: 0.46774,
    23: 0.5,
    24: 0.53153,
    25: 0.56221,
  },
  24: {
    1: 0.00116,
    2: 0.00254,
    3: 0.00593,
    4: 0.01025,
    5: 0.01717,
    6: 0.02568,
    7: 0.03724,
    8: 0.05109,
    9: 0.0676,
    10: 0.08647,
    11: 0.10786,
    12: 0.13144,
    13: 0.15705,
    14: 0.18441,
    15: 0.21331,
    16: 0.24343,
    17: 0.27458,
    18: 0.30644,
    19: 0.33878,
    20: 0.37136,
    21: 0.40397,
    22: 0.4364,
    23: 0.46847,
    24: 0.5,
    25: 0.53086,
  },
  25: {
    1: 0.00095,
    2: 0.00198,
    3: 0.00473,
    4: 0.00826,
    5: 0.014,
    6: 0.02121,
    7: 0.03113,
    8: 0.04318,
    9: 0.0577,
    10: 0.0745,
    11: 0.09378,
    12: 0.11524,
    13: 0.13877,
    14: 0.16414,
    15: 0.19117,
    16: 0.21954,
    17: 0.24913,
    18: 0.27962,
    19: 0.31079,
    20: 0.3424,
    21: 0.37424,
    22: 0.40609,
    23: 0.43779,
    24: 0.46914,
    25: 0.5,
  },
};
