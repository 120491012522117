import React, { Component } from 'react';
import { GlobalContext } from './GlobalContext';
import * as utils from '../../shared/ClientUtils';

class Adbar extends Component {
  static contextType = GlobalContext;
  state = { hidden: sessionStorage.getItem('adHidden') };

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return this.state.hidden ? (
      ''
    ) : (
      <nav
        className='navbar default-layout-navbar col-12 p-0 fixed-bottom d-flex flex-row'
        style={{ background: '#ffffff22' }}
      >
        <div
          className='text-center navbar-menu-wrapper d-flex align-items-center justify-content-center'
          style={{ backgroundColor: '#b8d3ff44', width: '100%' }}
        >
          <img
            src={'/rclm/leasing.svg'}
            alt='logo'
            style={{ height: '95%', margin: '5%', cursor: 'pointer' }}
            onClick={() =>
              utils.openLinkInNewTab('https://www.leasingautomobile.ro')
            }
          />
          <label
            className='badge badge-danger'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              this.setState({ hidden: true });
              sessionStorage.setItem('adHidden', true);
            }}
          >
            X
          </label>
        </div>
      </nav>
    );
  }
}

export default Adbar;
