/************************************************
 *
 * IMPORTS
 *
 ***********************************************/

/************************************************
 *
 * PUBLIC
 *
 ***********************************************/

export function reverseCheckers(checkers) {
  return checkers.map((_, i) => -checkers[25 - i]);
}

export function executeSequence({ checkers, sequence, reverse }) {
  let repetitions = 1;
  let hitDelta = 0;
  const reverseFactor = reverse ? -1 : 1;

  if (sequence.includes('(')) {
    const repStart = sequence.indexOf('(');
    const repEnd = sequence.indexOf(')');
    repetitions = parseInt(sequence.substring(repStart + 1, repEnd));
    sequence = sequence.substring(0, repStart);
  }

  if (sequence.endsWith('*')) {
    //hitting V's checker
    checkers[0] -= reverseFactor;
    hitDelta = 1;
    sequence = sequence.substring(0, sequence.length - 1);
  }

  const points = sequence.split('/');
  let srcIndex;
  if (points[0] === 'Bar') {
    srcIndex = 25;
  } else {
    srcIndex = parseInt(points[0]);
  }
  if (reverseFactor > 0 && checkers[srcIndex] < repetitions * reverseFactor) {
    return false;
  }
  checkers[srcIndex] -= repetitions * reverseFactor;

  if (points[1] !== 'Off') {
    const dst = parseInt(points[1]);
    if (reverseFactor === 1 && checkers[dst] != -1 && hitDelta > 0) {
      console.log(
        'WARNING! Incorrect hit sequence executed where destination is not -1: ' +
          sequence +
          ', when checkers are: ' +
          checkers
      );
      return false;
    }
    if (reverseFactor === 1 && checkers[dst] < -1) {
      console.log(
        'WARNING! Incorrect sequence where dst has at least 2 Villain checkers: ' +
          sequence +
          ', when checkers are: ' +
          checkers
      );
      return false;
    }
    if (reverseFactor === 1 && checkers[dst] === -1 && hitDelta === 0) {
      console.log(
        'WARNING! Incorrect sequence where dst has 1 Villain checker but hit was not indicated: ' +
          sequence +
          ', when checkers are: ' +
          checkers
      );
      return false;
    }
    if (reverseFactor < 0 && checkers[dst] < repetitions) {
      return false;
    }
    checkers[dst] += (repetitions + hitDelta) * reverseFactor;
  }

  return true;
}

/************************************************
 *
 * PRIVATE
 *
 ***********************************************/

function initValidMoves(position) {
  position.partialSequences = null;
  position.partialCheckers = null;

  if (!position.dice) {
    position.validMoves = [];
    return;
  }
  const isDouble = position.dice[0] === position.dice[1];
  const maxSequenceCount = isDouble ? 4 : 2;
  const jumps = isDouble ? position.dice.concat(position.dice) : position.dice;

  let validMoves;

  //Try finding moves with the largest possible sequence count
  for (
    let targetSequenceCount = maxSequenceCount;
    targetSequenceCount >= 1;
    targetSequenceCount--
  ) {
    validMoves = generateValidMoves({
      checkers:
        position.nextToAct > 0
          ? position.checkers.slice()
          : position.checkers.map((_, i) => -position.checkers[25 - i]),
      jumps,
      targetSequenceCount,
      isDouble,
    });
    if (validMoves.length > 0) {
      //Stop if we've found valid moves for the current sequence count
      break;
    }
  }

  position.validMoves = validMoves;
}

function processPartialSequence({ position, sequence, die }) {
  if (!position.partialSequences) {
    position.partialSequences = [];
  }
  position.partialSequences.push({
    sequence,
    die,
  });

  const newCheckers =
    position.nextToAct > 0
      ? (position.partialCheckers || position.checkers).slice()
      : reverseCheckers(position.partialCheckers || position.checkers);

  const sequenceSuccess = executeSequence({
    checkers: newCheckers,
    sequence,
    reverse: false,
  });
  if (!sequenceSuccess) {
    console.log(
      '[processPartialSequence] Failure to execute sequence ' +
        sequence +
        ' with die ' +
        die +
        ' in position ',
      position
    );
  }

  const newValidMoves = [];
  position.validMoves.forEach((move) => {
    const index = move.detailed.findIndex((s) => s === sequence);
    if (index !== -1) {
      move.detailed.splice(index, 1);
      newValidMoves.push(move);
    }
  });
  position.validMoves = newValidMoves;

  position.partialCheckers =
    position.nextToAct > 0 ? newCheckers : reverseCheckers(newCheckers);
}

function getCheckersAfterSequences(checkers, sequences, forVillain) {
  const newCheckers = forVillain
    ? checkers.map((_, i) => -checkers[25 - i])
    : checkers.slice();

  let success = true;
  sequences.forEach((sequence) => {
    const sequenceSuccess = executeSequence({
      checkers: newCheckers,
      sequence,
      reverse: false,
    });
    if (!sequenceSuccess) {
      success = false;
      console.log(
        '[getCheckersAfterSequences] Failure to execute sequence ' +
          sequence +
          ' with isVillain ' +
          forVillain +
          ' and checkers ',
        checkers
      );
    }
  });

  if (!success) {
    return null;
  }

  return forVillain
    ? newCheckers.map((_, i) => -newCheckers[25 - i])
    : newCheckers;
}

function getGameResult({ checkers, lastActed, gameOptions }) {
  if (lastActed < 0) {
    checkers = checkers.map((_, i) => -checkers[25 - i]);
  }
  for (let j = 1; j <= 25; j++) {
    if (checkers[j] > 0) {
      return 0;
    }
  }

  //We reach here only if lastActed has no more checkers on the board
  let totalOppCheckers = 0;
  for (let j = 0; j <= 24; j++) {
    if (checkers[j] < 0) {
      totalOppCheckers += -checkers[j];
    }
  }
  if (totalOppCheckers < 15) {
    //Single win for lastActed
    return lastActed;
  }
  //Check for backgammon
  for (let j = 0; j <= 6; j++) {
    if (checkers[j] < 0) {
      //Backgammon
      return gameOptions.backgammonFactor * lastActed;
    }
  }
  //We reach here if gammon
  return gameOptions.gammonFactor * lastActed;
}

function generateRandomDice(mustBeDifferent) {
  const dice = [randInt(1, 6), randInt(1, 6)];
  if (mustBeDifferent) {
    while (dice[0] === dice[1]) {
      dice[1] = randInt(1, 6);
    }
  }
  if (dice[0] >= dice[1]) {
    return dice;
  } else {
    return [dice[1], dice[0]];
  }
}

function generateRandomHeroVillain() {
  if (Math.random() < 0.5) {
    return 1;
  } else {
    return -1;
  }
}

function randInt(min, max) {
  return min + Math.floor(Math.random() * (max - min + 1));
}

function generateValidMoves({
  checkers,
  jumps,
  targetSequenceCount,
  isDouble,
}) {
  const moveList = [];
  const sequenceList = [];

  //First generate moves in which the first sequence uses the highest die
  generateSequences({
    checkers,
    moveList,
    sequenceList,
    jumps,
    targetSequenceCount,
    sequenceIndex: 0,
    highestPoint: 24,
    swapped: false,
    isDouble,
  });

  //Then generate moves in which the first sequence uses the lowest die
  //We do this by swapping the dice and restarting the backtracking
  if (
    !isDouble &&
    //Do it only for non-doubles
    (checkers[25] < 2 || targetSequenceCount === 1) &&
    //Don't do it with two on the bar unless we're looking for single-sequence move
    (targetSequenceCount > 1 || moveList.length === 0)
    //Don't do if only one die can be moved and we were able to move the highest die
  ) {
    generateSequences({
      checkers,
      moveList,
      sequenceList,
      jumps: [jumps[1], jumps[0]], //Swapping dice here
      targetSequenceCount,
      sequenceIndex: 0,
      highestPoint: 24,
      swapped: true,
    });
  }

  return moveList;
}

function generateSequences({
  checkers,
  moveList,
  sequenceList,
  jumps,
  targetSequenceCount,
  sequenceIndex,
  highestPoint,
  swapped,
  isDouble,
}) {
  if (sequenceIndex === targetSequenceCount) {
    const squashedMove = squashSequences({ sequenceList, isDouble }).join(' ');
    //We are pushing all solutions even if squashedMove duplicates an existing entry
    //That's because we want all detailed sequences available for partial move processing
    moveList.push({ full: squashedMove, detailed: sequenceList.slice() });
    return;
  }

  const jump = jumps[sequenceIndex];
  let lowestPoint = 1;
  if (checkers[25] > 0) {
    //On the bar, the only checker we can move is on 25
    highestPoint = 25;
    lowestPoint = 25;
  }

  for (let j = highestPoint; j >= lowestPoint; j--) {
    if (checkers[j] <= 0) {
      continue;
    }
    let sequence;
    const src = j === 25 ? 'Bar' : j + '';
    if (j >= jump + 1) {
      if (checkers[j - jump] >= -1) {
        sequence = src + '/' + (j - jump);
        if (checkers[j - jump] === -1) {
          sequence += '*';
        }
      } else {
        continue;
      }
    } else if (canBearOff({ checkers, index: j, jump })) {
      sequence = src + '/Off';
    } else {
      continue;
    }

    if (!executeSequence({ checkers, sequence, reverse: false })) {
      console.log(
        'CRITICAL ERROR: executeSequence failed in validMoves generation!'
      );
    }
    sequenceList.push(sequence);

    generateSequences({
      checkers,
      moveList,
      sequenceList,
      jumps,
      targetSequenceCount,
      sequenceIndex: sequenceIndex + 1,
      highestPoint: swapped ? j - 1 : j, //if we have swapped, no need to move two checkers from the same point
      swapped,
      isDouble,
    });

    if (!executeSequence({ checkers, sequence, reverse: true })) {
      console.log(
        'CRITICAL ERROR: executeSequence failed in validMoves generation!'
      );
    }
    sequenceList.pop();
  }
}

function canBearOff({ checkers, index, jump }) {
  for (let j = 7; j <= 25; j++) {
    if (checkers[j] > 0) {
      return false;
    }
  }

  if (index === jump) {
    return true;
  }

  for (let j = 6; j > index; j--) {
    if (checkers[j] > 0) {
      return false;
    }
  }

  return true;
}

function squashSequences({ sequenceList, isDouble }) {
  const squashedList = [];
  const dupSeqList = sequenceList.slice();

  //Squash

  let j = 0;
  while (j < dupSeqList.length) {
    const sequence = dupSeqList[j];
    let squashedSequence = sequence.substring(0);

    if (!sequence.endsWith('*')) {
      let k = j + 1;
      while (k < dupSeqList.length) {
        const sqSeqSplit = squashedSequence.split('/');
        const nextSeqSplit = dupSeqList[k].split('/');
        if (sqSeqSplit[1] === nextSeqSplit[0]) {
          squashedSequence = sqSeqSplit[0] + '/' + nextSeqSplit[1];
          dupSeqList.splice(k, 1);
        } else {
          k++;
        }
      }
    }

    squashedList.push(squashedSequence);
    j++;
  }

  if (!isDouble) {
    return squashedList;
  }

  //Group for doubles

  const groupedList = [];
  j = 0;
  while (j < squashedList.length) {
    const sequence = squashedList[j];
    let rawSequence;
    const starIndex = sequence.indexOf('*');
    if (starIndex !== -1) {
      rawSequence = sequence.substring(0, starIndex);
    } else {
      rawSequence = sequence.substring(0);
    }

    let reps = 1; //repetitions
    let k = j + 1;
    while (k < squashedList.length) {
      const nextSeq = squashedList[k];
      if (nextSeq === rawSequence) {
        reps++;
        squashedList.splice(k, 1);
      } else {
        k++;
      }
    }

    const groupedSequence = reps > 1 ? sequence + '(' + reps + ')' : sequence;
    groupedList.push(groupedSequence);
    j++;
  }
  return groupedList;
}
